import { AfterViewInit, Component, Input } from '@angular/core';

declare let bootstrap: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
})
export class IconComponent implements AfterViewInit {
  @Input() type: string = '';
  @Input() description: string = '';
  @Input() title: string = '';
  @Input() legend: string = '';
  @Input() color: string = '';
  @Input() style: string = '';
  @Input() tooltip: boolean = false;
  @Input() tooltipTitle: string = '';
  @Input() tooltipPosition: string = '';
  @Input() customCssClass: string = '';
  @Input() customIconClass: string = '';
  @Input() customTextClass: string = '';
  @Input() customDescriptionClass: string = '';

  ngAfterViewInit(): void {
    if (this.tooltip) {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]'),
      );
      tooltipTriggerList.forEach(function (tooltipTriggerEl) {
        try {
          new bootstrap.Tooltip(tooltipTriggerEl);
        } catch (error) {
          //Temporary need to mock bootstrap instantiation for unit test
          console.error(error);
        }
      });
    }
  }

  applyStyle(style: string): string {
    switch (style) {
      case 'outlined':
        return 'material-icons-outlined';
      case 'rounded':
        return 'material-icons-round';
      default:
        return 'material-icons';
    }
  }
}
