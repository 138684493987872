import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'paragraph-typo',
  templateUrl: './paragraph.component.html',
})
export class ParagraphComponent {
  @Input() text: string = '';
  @Input() legend: string = '';
  @Input() customCssClass: string = '';
}
