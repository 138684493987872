import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../../app/security/authentication.service';
import { addHourToDate } from '../../utils/date.utils';


@Injectable({providedIn : 'root'})
export class TimeZoneConverter{

  constructor(private authenticationService: AuthenticationService) {}

  timeZoneMap : Map<string,string> =  new Map([['DGS','UTC']]);
  private refineryCode  = this.authenticationService.getcurrentUserRoles();
  timeZone = this.timeZoneMap.get(this.refineryCode? this.refineryCode[0]  :'DGS') ?? 'UTC';

  convertUTCHourToRefineryTZ(hour: string): string{
    return addHourToDate(new Date().toISOString(),hour).
      toLocaleTimeString('fr-FR',{timeZone : this.timeZone});
  }

  convertUTCDateToRefineryTZ(date: string |null): string{
    if(date === null){return '';}
    return new Date(date).
      toLocaleTimeString('fr-FR',{timeZone : this.timeZone});
  }
}
