import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as moment from 'moment-timezone';

export interface SegmentedControl {
  id: number;
  openDay:Date
}

@Component({
  selector: 'app-segmented-controls',
  templateUrl: './segmented-controls.component.html',
  styleUrls: ['./segmented-controls.component.scss']
})
export class SegmentedControlsComponent implements OnChanges {
  @Input() public controls!: SegmentedControl[];
  @Input() public defaultSelectedControlId!: number;
  @Input() public isLowerCase: boolean = true;
  @Input() public isDisabled: boolean = true;
  @Output() activeDayOutput: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() selectControlEventEmitter: EventEmitter<number> =
    new EventEmitter<number>();

  public selectedControlId!: number;
  public activeDay!:Date;

  public ngOnChanges(): void {
    this.selectedControlId = this.defaultSelectedControlId;
  }

  public setSelectedControl(newControl: SegmentedControl): void {
    if(this.isDisabled) return;
    this.selectedControlId = newControl.id;
    this.selectControlEventEmitter.emit(this.selectedControlId);
    this.activeDayOutput.emit(newControl.openDay);
  }

  formatDate(date: string | Date): string {
    const dateObj = moment(date);
    if (dateObj.isSame(moment(), 'day')) {
      return 'Aujourd\'hui';
    } else {
      return dateObj.locale('fr').format('ddd D MMM');
    }
  }
}