import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'text-typography',
  templateUrl: './text-typography.component.html',
  styleUrls: ['./text-typography.component.css'],
})
export class TextTypographyComponent {
  @Input() customCssClass: string | undefined;
}
