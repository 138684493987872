<div class="planning-table" *ngFor="let timeline of timelines">
    <!-- header columns -->
    <div class="planning-header">
        <!-- macro slots -->
        <div class="planning-header-timeline">
            <icon type="expand_less" customCssClass="me-2" class="pointer"></icon>
            <text-typography customCssClass="header-text-3">{{timeline.range}}</text-typography>
            <text-typography [ngClass]="timeline.freeSlots > 0 ? 'free-slots' : 'no-free-slots'" customCssClass="bold-text-s"
                pTooltip="Créneaux libres" tooltipPosition="top" tooltipStyleClass="tooltip-free-slots bold-text-xs">{{timeline.freeSlots}}</text-typography>
        </div>
        <!-- docks -->
        <div class="planning-header-columns">
            <div class="planning-header-columns-title" [style.width]="100/timeline.columns.length+'%'"
                *ngFor="let column of timeline.columns">
                <text-typography customCssClass="header-text-4 me-4">{{column.name}}</text-typography>
                <!-- column-products -->
                <div class="planning-header-columns-badge">
                    <text-typography class="planning-header-columns-badge-text" customCssClass="bold"
                        *ngFor="let colBadgedText of column.badgedTexts">{{colBadgedText}}</text-typography>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->
    <div class="planning-body">
        <!-- micro slots -->
        <div class="planning-body-timeline">
            <div class="planning-body-timeline-title" *ngFor="let step of timeline.steps">
                <text-typography customCssClass="regular-text-s">{{step.displayName}}</text-typography>
            </div>
        </div>
        <!-- orders -->
        <div class="planning-body-content">
            <div class="planning-body-item" *ngFor="let column of timeline.columns; let colIndex = index">
                <div *ngFor="let step of timeline.steps" [style.height]="100/timeline.steps.length+'%'"
                    class="planning-body-item-wrapper">
                    <ng-container *ngIf="(column.cells | itemFinder: step.value) as cell">
                        <div 
                        class="planning-body-item-container" 
                        [ngClass]="cell.emptyCell ? '': 'active'"
                        tooltipStyleClass="tooltip-info-planning-table"
                        [pTooltip]="tooltipCellContent"
                        [tooltipDisabled]="!!cell.emptyCell"
                        [tooltipPosition]="'right'">
                            <text-typography customCssClass="truncated-text">{{cell.mainText}}</text-typography>
                            <div class="planning-body-item-info">
                                <text-typography
                                    customCssClass="me-2 regular-text-s">{{cell.secondaryText}}</text-typography>
                                <!-- copy icon  -->
                                <icon (click)="copyToClipboard(cell.secondaryText)" *ngIf="cell.secondaryText"
                                    type="content_copy" class="copy-icon" customCssClass="me-2 pointer"
                                    customIconClass="material-icons small-icon-size" onKeyPress=""></icon>
                                <text-typography *ngIf="cell.badgedText" class="planning-body-columns-badge-text"
                                    customCssClass="bold">{{cell.badgedText}}</text-typography>

                                <icon *ngIf="cell.realLoadingDate"
                                type="check" class="check-icon" customCssClass="me-5"
                                customIconClass="material-icons large-icon-size"></icon>
                                
                                <icon *ngIf="!cell.realLoadingDate && (cell.initialStartDate && cell.startDate) && cell.initialStartDate !== cell.startDate"
                                type="history_toggle_off" class="history-toggle-icon" customCssClass="me-5" 
                                customIconClass="material-icons large-icon-size"></icon>
                            </div>
                        </div>
                        <!-- tooltip template -->
                        <ng-template #tooltipCellContent>
                            <div class="planning-tooltip-content">
                                <ng-container *ngFor="let content of tooltipContent">
                                    <span *ngIf="!content.conditionDisplay || content.conditionDisplay(cell)">
                                        {{(cell[content.cellPropertyName] ? content.displayName : '')
                                            + (cell[content.cellPropertyName] | tooltipFormat: content.type) 
                                            + (content.cellPropertySecondName && cell[content.cellPropertySecondName] && content.displaySecondName ? content.displaySecondName : '') 
                                            + (content.cellPropertySecondName ? 
                                                (cell[content.cellPropertySecondName] | tooltipFormat: content.type) : '')}}</span>
                                </ng-container>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>