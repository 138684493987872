import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonElementComponent } from './components/atoms/button/button-element.component';
import { GridRowComponent } from './components/molecules/grid/row/grid-row.component';
import { GridColComponent } from './components/molecules/grid/col/grid-col.component';
import { GridComponent } from './components/organisms/grid/grid.component';
import { TitleTypographyComponent } from './components/atoms/typography/title/title-typography.component';
import { DescriptionComponent } from './components/molecules/typo/description/description.component';
import { IconComponent } from './components/molecules/icon/icon.component';
import { ParagraphComponent } from './components/molecules/typo/paragraph/paragraph.component';
import { CardHeaderComponent } from './components/molecules/card/header/card-header.component';
import { CardBodyComponent } from './components/molecules/card/body/card-body.component';
import { CardComponent } from './components/organisms/card/card.component';
import { LegendTypographyComponent } from './components/atoms/typography/legend/legend-typography.component';
import { TextTypographyComponent } from './components/atoms/typography/text/text-typography.component';
import { TabComponent } from './components/molecules/tab/tab.component';
import { TabsComponent } from './components/organisms/tabs/tabs.component';
import { LinkElementComponent } from './components/atoms/link/link-element.component';
import { ImageElementComponent } from './components/atoms/image/image-element.component';
import { TableComponent } from './components/organisms/table/table.component';
import { BadgeComponent } from './components/atoms/badge/badge.component';
import { SpinnerComponent } from './components/organisms/spinner/spinner.component';
import { HeaderComponent } from './components/templates/header/header.component';
import { CalendarCardComponent } from './components/templates/calendar-card/calendar-card.component';
import { SumPipe } from './pipes/sum.pipe';
import { CalendarAccordionComponent } from './components/templates/calendar-accordion/calendar-accordion.component';
import { DateComparatorPipe } from './pipes/date-comparator.pipe';
import { CalendarCardUnavaibilitiesComponent } from './components/templates/calendar-card-unavaibilities/calendar-card-unavaibilities.component';
import {TooltipModule} from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DropdownModule} from 'primeng/dropdown';
import { SlotTZConverterPipe } from './pipes/slot-tzconverter.pipe';
import { PlanningTableComponent } from './components/templates/planning-table/planning-table.component';
import {SegmentedControlsComponent } from './components/templates/segmented-controls/segmented-controls.component';
import { ItemFinderPipe } from './components/templates/planning-table/pipes/item-finder.pipe';
import { TooltipFormatPipe } from './components/templates/planning-table/pipes/tooltip-format.pipe';
import { ModalElementComponent } from './components/organisms/modal/modal-element.component';





const BASE_MODULES = [RouterModule, CommonModule, FormsModule, ReactiveFormsModule];
const DIRECTIVE_PRIME_NG = [TooltipModule, OverlayPanelModule, AutoCompleteModule, DropdownModule];
const CUSTOM_MODULES = [CalendarModule, SlotTZConverterPipe];
const SHARED_COMPONENTS = [
  ButtonElementComponent,
  GridRowComponent,
  GridColComponent,
  GridComponent,
  TitleTypographyComponent,
  DescriptionComponent,
  IconComponent,
  ParagraphComponent,
  CardHeaderComponent,
  CardBodyComponent,
  CardComponent,
  LegendTypographyComponent,
  TextTypographyComponent,
  TabComponent,
  TabsComponent,
  LinkElementComponent,
  ImageElementComponent,
  TableComponent,
  BadgeComponent,
  SpinnerComponent,
  HeaderComponent,
  CalendarCardComponent,
  CalendarAccordionComponent,
  CalendarCardUnavaibilitiesComponent,
  PlanningTableComponent,
  SegmentedControlsComponent,
  ModalElementComponent
];

const SHARED_PIPES = [
  SumPipe,
  DateComparatorPipe,
  DateFormatPipe,
  ItemFinderPipe,
  TooltipFormatPipe
];

@NgModule({
  declarations: [...SHARED_COMPONENTS, ...SHARED_PIPES],
  imports: [...BASE_MODULES, ...DIRECTIVE_PRIME_NG, ...CUSTOM_MODULES],
  exports: [...BASE_MODULES, ...SHARED_COMPONENTS, ...SHARED_PIPES],
})
export class SharedModule {}
