import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable, filter, of, switchMap } from 'rxjs';

export const activateLogin = ():
  | Observable<boolean>
  | Promise<boolean>
  | boolean => {
  const msalBroadcastService = inject(MsalBroadcastService);
  const msalService = inject(MsalService);
  const router = inject(Router);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    switchMap(() => {
      if (msalService.instance.getActiveAccount()) {
        router.navigate(['/orders']);
        return of(false);
      }
      return of(true);
    }),
  );
};
