import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'title-typography',
  templateUrl: './title-typography.component.html',
  styleUrls: ['./title-typography.component.css'],
})
export class TitleTypographyComponent {
  @Input() level: number = 1;
  @Input() customCssClass: string | undefined;
}
