import {
  Component,
  ContentChildren,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { GridRowComponent } from '../../molecules/grid/row/grid-row.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GridComponent {
  @ContentChildren(GridRowComponent) rows:
    | QueryList<GridRowComponent>
    | undefined;

  @Input() customCssClass: string | undefined = '';
}
