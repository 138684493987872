/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, type PipeTransform } from '@angular/core';
import { TimeZoneConverter } from 'src/shared/infrastructure/resources/time-zone-converter';
import { PlanningTooltipContentType } from 'src/shared/models/wrappers/planning-timeline.interface';
import { timeToHourAndMinutes } from 'src/shared/utils/date.utils';

@Pipe({
  name: 'tooltipFormat'
})
export class TooltipFormatPipe implements PipeTransform {

  constructor(private readonly timeZoneConverter: TimeZoneConverter) { }

  transform(value: any, type: PlanningTooltipContentType): string {
    if (type === PlanningTooltipContentType.DATE) {
      const zonedDate = this.timeZoneConverter.convertUTCDateToRefineryTZ(value);
      if (zonedDate !== 'Invalid Date' && value !== null) {
        return timeToHourAndMinutes(zonedDate);
      } else {
        if(value !== null) console.error('Invalid date in tooltipFormat pipe for value: ', value);
        return '';
      }
    } else if (type === PlanningTooltipContentType.TRUNCATE) {
      return '...' + value?.slice(-6);
    } else {
      return value || '';
    }
  }
}
