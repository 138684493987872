import { Pipe, PipeTransform } from '@angular/core';
import { Slot } from '../models/macro-slot.interface';
import { TimeZoneConverter } from '../infrastructure/resources/time-zone-converter';
import { timeToHour } from '../utils/date.utils';

@Pipe({
  name: 'slotTZConverter',
  standalone: true
})
export class SlotTZConverterPipe implements PipeTransform {
  constructor(private timeZoneConverter: TimeZoneConverter) {
  }

  transform(slot: Slot | undefined): Slot {
    if (slot) {
      return {
        startHour: timeToHour(this.timeZoneConverter.convertUTCHourToRefineryTZ(slot.startHour)),
        endHour: timeToHour(this.timeZoneConverter.convertUTCHourToRefineryTZ(slot.endHour)),
        macroSlotId: ''
      };
    } else {
      return {startHour:'',endHour:'', macroSlotId: ''};

    }


  }

}
