/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, startWith } from 'rxjs';
import { ErrorMessagesConfiguration } from './state-messages.utils';

const errorMessenger$: BehaviorSubject<AsyncError> = new BehaviorSubject<AsyncError>({message: '', status : 0});
const successMessenger$: BehaviorSubject<string> = new BehaviorSubject<string>('');

export function setAsyncState<T>(obs$: Observable<T>, defaultResponse: any, error?: ErrorMessagesConfiguration[], success?: string): Observable<StateWrapper<T>> {
return obs$.pipe(
    map((response: T) => {
        if(success) triggerSuccessToast(success);
        return wrapResponse<T>(AsyncState.LOADED, response);
    }),
    startWith(wrapResponse(AsyncState.LOADING, defaultResponse)),
    catchError((errorResponse: HttpErrorResponse) => {
        return handleAsyncError(defaultResponse, errorResponse, error);
    })
);
}

export function setAsyncStateNoStart<T>(obs$: Observable<T>, defaultResponse: any, error?: ErrorMessagesConfiguration[], success?: string): Observable<StateWrapper<T>> {
    return obs$.pipe(
        map((response: T) => {
            if(success) triggerSuccessToast(success);
            return wrapResponse<T>(AsyncState.LOADED, response);
        }),
        catchError((errorResponse: HttpErrorResponse) => {
            return handleAsyncError(defaultResponse, errorResponse, error);
        })
    );
}

export function handleAsyncError(replacedResponse: any, error: HttpErrorResponse, customMessage?: ErrorMessagesConfiguration[]): Observable<StateWrapper<any>> {
  const msg = customMessage?.find((message) => message.status === error.status);
  const defaultMessage = customMessage?.find((message) => message.isDefault);
  let asyncError: AsyncError = {
    message: defaultMessage?.message ?? error.error.errorCode,
    status: error.status,
    isPopup: defaultMessage?.isPopup ?? false
  };
  if(msg !== undefined) {
    asyncError = {
      message: msg.message ?? error.error.errorCode,
      status: error.status,
      isPopup: msg.isPopup
    };
  }
    triggerError(asyncError);
    return of(wrapResponse(AsyncState.ERROR, replacedResponse, asyncError));
}

export function triggerError(error: AsyncError) {
    errorMessenger$.next(error);
}

export function triggerSuccessToast(message: string) {
    successMessenger$.next(message);
}

export function getErrorMessenger(): BehaviorSubject<AsyncError> {
    return errorMessenger$;
}

export function getSuccessMessenger(): BehaviorSubject<string> {
    return successMessenger$;
}

export enum AsyncState {
    INIT, LOADING, LOADED, ERROR
}

export interface AsyncError {
    message: string,
    status?: number,
    isPopup?: boolean
}

export interface StateWrapper<T> {
    state: AsyncState,
    response: T,
    error?: AsyncError
}

export function wrapResponse<T>(state: AsyncState, response: T, error?: AsyncError): StateWrapper<T> {
return {
    state: state,
    response: response,
    error: error
};
}

