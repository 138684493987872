<header></header>
<router-outlet></router-outlet>
<p-toast position="top-center" key="errorAlert"></p-toast>
<p-toast position="top-center" key="successAlert"></p-toast>

<modal-element [header]="'Saisie momentanément indisponible'" [modalContentClass]="'modal-asph-1'" #optimInformationConfirmDialog>
  <ng-container body>
    {{errorMessageBody}}
  </ng-container>
  <ng-container footer>
    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="optimInformationConfirmDialog.close()">J'ai compris</button>
  </ng-container>
</modal-element>
