<div class="calendar-card" [class.active]="active">
  <div class="d-flex flex-column h-100">
    <div class="d-flex">
      <title-typography customCssClass="ms-3 mt-3" [level]="dateSize">
        {{ (date | dateComparator) === 0 ? "Aujourd'hui" : (date | date: 'EEE d MMM') }}
      </title-typography>
      <div class="pill">{{ pillValue }}</div>
    </div>
    <div class="mt-4 mb-4 product-list">
      <ng-container *ngFor="let product of products">
        <div class="product-row">
          <text-typography customCssClass="ms-3">{{ product.name }}</text-typography>
          <text-typography class="ms-auto me-3" customCssClass="bold">{{ product.tonnage }}</text-typography>
        </div>
      </ng-container>
    </div>
    <div *ngIf="totalTonnage !== null && totalTonnage !== undefined" class="fit-content ms-auto me-3 mt-auto">
      <title-typography [level]="3">{{ totalTonnage + ' t' }}</title-typography>
    </div>
  </div>
</div>
