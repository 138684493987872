<div class="calendar-accordion" *ngFor="let input of inputs; let rowIndex = index" [class.active]="selectedElement.rowIndex === rowIndex">
  <div class="accordion-row">
    <div class="accordion-header-cell">
      <text-typography customCssClass="header-text-3">{{ input.headerName }}</text-typography>
      <div class="tooltip-container">
        <div class="tooltip-content">
          <div class="arrow"></div>
          <div class="child-component">Chargements</div>
        </div>
        <div class="pill-abs bold-text-s">{{ input.pill || 0 }}</div>
      </div>
    </div>
    <div class="accordion-container">
      <div class="accordion-container-body">
        <div
          *ngFor="let data of input.dataset; let colIndex = index"
          class="accordion-cell"
          (click)="onSelectCell(input, data, rowIndex, colIndex); collapse(rowIndex, colIndex)"
          [class.selected]="selectedElement.colIndex === colIndex"
          onkeypress=""
        >
          <div class="pill-accordion bold-text-s">{{ data.pill || 0 }}</div>
          <div class="accordion-cell-group">
            <text-typography customCssClass="regular-text-xs">{{ data.title }}</text-typography>
            <text-typography customCssClass="bold-text-l">{{ data.name }}</text-typography>
          </div>
        </div>
      </div>
      <div class="detail-panel" *ngIf="selectedElement.rowIndex === rowIndex">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
