<div class="modal fade" id="{{id}}" [ngClass]="{'show': showModalBox, 'd-block': showModalBox}" tabindex="-1" [attr.aria-labelledby]="id+'Label'" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div [ngClass]="['modal-content', modalContentClass]">
      <div class="modal-header">
        <h1 class="modal-title" id="{{id+'Label'}}">{{header}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
