import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  AccordionCardDataset,
  AccordionCardInput,
  AccordionCardResponse,
} from 'src/shared/models/wrappers/accordion-card-input.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'calendar-accordion',
  templateUrl: './calendar-accordion.component.html',
  styleUrls: ['./calendar-accordion.component.scss'],
})
export class CalendarAccordionComponent implements OnChanges {

  @Input() inputs: AccordionCardInput[] = [];
  @Output() selectCell: EventEmitter<AccordionCardResponse> =
    new EventEmitter<AccordionCardResponse>();
  selectedElement: SelectedElement = { rowIndex: null, colIndex: null };

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['inputs']) {
      this.closeAccordion();
    }
  }

  collapse(rowIndex: number, colIndex: number) {
    if (this.isSameSelectedCell(rowIndex, colIndex)) {
      this.closeAccordion();
      return;
    }
    this.selectedElement.rowIndex = rowIndex;
    this.selectedElement.colIndex = colIndex;
  }

  onSelectCell(
    input: AccordionCardInput,
    dataset: AccordionCardDataset,
    rowIndex: number,
    colIndex: number,
  ) {
    if (this.isSameSelectedCell(rowIndex, colIndex)) return;

    const accordionResponse: AccordionCardResponse = {
      metadata: input.metadata,
      headerName: input.headerName,
      name: dataset.name,
      title: dataset.title,
    };
    this.selectCell.emit(accordionResponse);
  }

  closeAccordion() {
    this.selectedElement.rowIndex = null;
    this.selectedElement.colIndex = null;
  }

  isSameSelectedCell(rowIndex: number, colIndex: number): boolean {
    return (
      rowIndex === this.selectedElement.rowIndex &&
      colIndex === this.selectedElement.colIndex
    );
  }

  protected readonly console = console;
}

interface SelectedElement {
  rowIndex: number | null;
  colIndex: number | null;
}
