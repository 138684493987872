import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'grid-col',
  templateUrl: './grid-col.component.html',
})
export class GridColComponent {
  @Input() customCssClass: string = '';
}
