import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { GridColComponent } from '../col/grid-col.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'grid-row',
  templateUrl: './grid-row.component.html',
  styleUrls: ['./grid-row.component.css'],
})
export class GridRowComponent {
  // @ts-ignore
  @ContentChildren(GridColComponent) cols: QueryList<GridColComponent>;

  @Input() customCssClass: string | undefined;
}
