import { Component, Input } from '@angular/core';
import { ProductQuantity } from 'src/shared/models/product-quantity.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'calendar-card',
  templateUrl: './calendar-card.component.html',
  styleUrls: ['./calendar-card.component.scss'],
})
export class CalendarCardComponent {
  // data
  @Input() date: Date | null = null;
  @Input() products: Array<ProductQuantity> = [];
  @Input() totalTonnage: number | null = null;
  @Input() pillValue: number | null = null;
  // styling
  @Input() dateSize: number = 3;
  @Input() active: boolean = false;
}
