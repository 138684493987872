import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {OrderPlanning} from '../../../models/order-planning.interface';
import {LoadingPointStartEndHour} from '../../../models/planning.interface';
import {PlanningColumn, PlanningTooltipContentType} from '../../../models/wrappers/planning-timeline.interface';
import {Piste} from '../../../models/grade.interface';
import {UnavailabilitySummary} from '../../../models/unavailability-summary.interface';
import {GridUnavailability, Unavailability} from '../../../models/unavailability.interface';

@Component({
    selector: 'app-optimized-planning-table',
    templateUrl: './optimized-planning-table.component.html',
    styleUrl: './optimized-planning-table.component.scss'
})
export class OptimizedPlanningTableComponent implements OnChanges {
    @Input() timelines: OrderPlanning[] = [];
    @Input() loadingPointStartEndHour: LoadingPointStartEndHour | undefined;
    @Input() planningColumns: PlanningColumn[] = [];
    @Input() unavaibilities: UnavailabilitySummary[] = [];
    timeSlots: string[] = [];
    pistes: Piste[] = [];
    schedulerStartTime: number = 0;
    schedulerEndTime: number = 0;
    tooltipTask: OrderPlanning | null = null;
    tooltipUnavailabilities: Unavailability[] | null = null;
    hoverOverIcon = false;
    displayedGrades = ['10/20', '15/25', '20/30', '35/50', '50/70', '70/100', '105/135', '160/220'];
    headerHeight: number = 110;
    slotHeight: number = 130;
    protected readonly PlanningTooltipContentType = PlanningTooltipContentType;

    constructor() {
        this.generateTimeSlots();
    }

    getUnavailabilityDate(dateString: string): Date {
        return new Date(dateString);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['timelines'] || changes['planningColumns'] || changes['unavaibilities']) {
            this.initializePistes();
            this.timelines.forEach(timeline => {
                let piste = this.pistes.find(piste => piste.id === timeline.dockId);
                if (piste && piste?.tasks) {
                    piste.tasks.push(timeline);
                    this.pistes.forEach(piste => {
                        piste.grades?.sort((a, b) => {
                            const aValue = parseInt(a.split('/')[0]);
                            const bValue = parseInt(b.split('/')[0]);
                            return aValue - bValue;
                        });
                    });
                }
            });

        }
        if (changes['loadingPointStartEndHour'] && this.loadingPointStartEndHour) {
            this.schedulerStartTime = +this.transformUtcToLocalTime(this.loadingPointStartEndHour.openingHour).split(':')[0];
            this.schedulerEndTime = +this.transformUtcToLocalTime(this.loadingPointStartEndHour.closingHour).split(':')[0];
            this.generateTimeSlots();
        }
    }

    generateTimeSlots() {
        this.timeSlots = [];
        for (let hour = this.schedulerStartTime; hour < this.schedulerEndTime; hour++) {
            this.timeSlots.push(`${hour}h`);
            if (hour < this.schedulerEndTime) this.timeSlots.push(`${hour}h30`);
        }
    }

    initializePistes() {
        this.pistes = [];
        for (const element of this.planningColumns) {
            let unavailabilities = this.unavaibilities
                .flatMap(dayEntry => dayEntry.unavailabilities)
                .filter(unavailability => unavailability.name === element.name);
            this.pistes.push({
                id: element.id,
                name: element.name,
                tasks: [],
                grades: element.grades,
                unavailabilities: this.mergeIntersectingUnavailabilities(unavailabilities)
            });
        }
    }


    mergeIntersectingUnavailabilities(unavailabilities: Unavailability[]): GridUnavailability[] {
        if (unavailabilities.length === 0) return [];

        unavailabilities.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

        const mergedUnavailabilities: GridUnavailability[] = [];
        let currentUnavailability: GridUnavailability = {
            startDate: unavailabilities[0].startDate,
            endDate: unavailabilities[0].endDate,
            unavailabilities: [unavailabilities[0]]
        };

        for (let i = 1; i < unavailabilities.length; i++) {
            const nextUnavailability = unavailabilities[i];
            const currentEndDate = new Date(currentUnavailability.endDate);
            const nextStartDate = new Date(nextUnavailability.startDate);

            if (nextStartDate <= currentEndDate) {
                currentUnavailability.endDate = new Date(Math.max(currentEndDate.getTime(), new Date(nextUnavailability.endDate).getTime())).toISOString();
                currentUnavailability.unavailabilities.push(nextUnavailability);
            } else {
                mergedUnavailabilities.push(currentUnavailability);
                currentUnavailability = {
                    startDate: nextUnavailability.startDate,
                    endDate: nextUnavailability.endDate,
                    unavailabilities: [nextUnavailability]
                };
            }
        }

        mergedUnavailabilities.push(currentUnavailability);

        return mergedUnavailabilities;
    }

    getTaskPosition(startTime: Date): string {
        const pixelsPerMinute = this.slotHeight / 30; // slotHeight per 30 minutes
        const hour = new Date(startTime).getUTCHours();
        const minutes = new Date(startTime).getUTCMinutes();
        // Calculate total minutes from the scheduler's start time
        const startMinutes = (hour - this.schedulerStartTime) * 60 + (minutes || 0);
        const taskTopPosition = startMinutes * pixelsPerMinute;
        return `${taskTopPosition + this.headerHeight}px`;
    }

    getTaskHeight(startTime: Date, endTime: Date): string {
        const pixelsPerMinute = this.slotHeight / 30; // slotHeight per 30 minutes
        const startHour = new Date(startTime).getUTCHours();
        const startMinutes = new Date(startTime).getUTCMinutes();
        const endHour = new Date(endTime).getUTCHours();
        const endMinutes = new Date(endTime).getUTCMinutes();

        const durationMinutes =
            (endHour - startHour) * 60 + (endMinutes || 0) - (startMinutes || 0);

        return `${durationMinutes * pixelsPerMinute - 2}px`;
    }

    getHeaderGradeClass(grade: string, piste: Piste): string {
        if (piste.grades?.includes(grade)) {
            return 'grade-' + grade.replace('/', '-').toLowerCase();
        }
        return '';
    }

    getGradeClass(grade: string): string {
        const gradeClasses: { [key: string]: string } = {
            '35/50': 'grade-35-50',
            '50/70': 'grade-50-70',
            '70/100': 'grade-70-100',
            '160/220': 'grade-160-220'
        };
        return gradeClasses[grade] || '';
    }

    transformUtcToLocalTime(utcTime: string): string {
        const utcDate = new Date(`1970-01-01T${utcTime}Z`);
        const localDate = new Date(utcDate.toLocaleString('en-US', {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}));
        return localDate.toTimeString().split(' ')[0];
    }

    setTooltipTask(task: OrderPlanning | null) {
        this.tooltipTask = task;
    }

    copyToClipboard(textToCopy: string) {
        navigator.clipboard.writeText(textToCopy);
    }

    setTooltipUnavailabilities(unavailability: Unavailability[] | null) {
        this.tooltipUnavailabilities = unavailability;
    }


}
