export function  siteCodeToName(siteCode: string) : string{
  switch (siteCode) {
    case 'DGS':
      return 'Donges';
    case 'GONF':
      return 'Normandie';
    default:
      return siteCode;
  }
}
export function    extractRoleCode(role: string): string {
  if (role.includes('_')) {
    return role.split('_')[0];
  }
  return role;
}
export function rolesToSites(roles: Array<string> | undefined) : Array<{code: string, name: string}>{
  if (!roles) {
    return [];
  }
  const sites = roles
    .map(role => extractRoleCode(role))
    .filter((site, index, array) => array.indexOf(site) === index)
    .map(site => {
      return {
        code: site,
        name: siteCodeToName(site),
      };
    });

  return  sites;
}

export function canUpdateUnavaibility(roles: Array<string> | undefined): boolean {
  if (!roles) {
    return false;
  }
  return roles?.some(role => role.includes('UNAVAILABILITIES'));
}
