import * as moment from 'moment-timezone';

export function compareDates(date1: Date, date2: Date): number | null {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  try {
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  } catch (ex) {
    console.error(ex);
  }
  return null;
}

export function timeToNumber(hour: string): number {
  return parseInt(hour.split(':')[0]);
}

export function numberToHour(
  hour: number,
  minute: number = 0,
  second: number = 0
): string {
  return `${hour}:${minute}:${second}Z`;
}

export function timeToHour(hour: string): string {
  return `${hour.split(':')[0].replace(/^0(?:0:0?)?/, '')}h`;
}

export function timeToHourAndMinutes(hour: string): string {
  const hours = hour.split(':')[0].replace(/^0(?:0:0?)?/, '');
  const minutes = hour.split(':')[1].replace(/\.00$/,'');
  return `${hours}h${minutes === '00' ? '' : minutes}`;
}

export function addMacroSlotToDate(date: string, hour: string): string {
  return addHourToDate(date, hour).toISOString();
}

export function addHourToDate(date: string, hour: string): Date {
  const split: string[] = hour.split(':');
  const hours: number = parseInt(split[0], 10);
  const minutes: number = parseInt(split[1] ? split[1] : '0', 10);
  const seconds: number = parseInt(split[2] ? split[2] : '0', 10);

  const newDate: Date = new Date(date);
  newDate.setUTCHours(hours, minutes, seconds, 0);
  return newDate;
}

export function moveDays(date: Date, days: number, increment: number): Date {
  if (!date || isNaN(date.getTime())) {
    return new Date();
  }
  let secureIndex = 0;
  while (days > 0 && secureIndex < 50) {
    secureIndex++;
    if (date.getDay() != 0 && date.getDay() != 6) {
      days--;
    }
    date.setDate(date.getDate() + increment);
  }
  return date;
}

export function dateTimeZoneToUTC(clientDate: string): string {
  if(clientDate !=''){
    return moment.parseZone(clientDate).format();
  }
  return '';
}

export const dateToFormatYearMonthDay=(date: moment.Moment): string =>{
  if(date.isValid()){
    return  date.format('YYYY-MM-DD');
  }
  return 'invalid Date';
};


export function rangeFormat(startHour: string, endHour: string): string {
  return `${timeToNumber(startHour)}h - ${timeToNumber(endHour)}h`;
}
