import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  EventType,
  RedirectRequest,
} from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ConnectedUser } from '../types/connected-user.interface';
import { EventError } from '@azure/msal-browser/dist/event/EventMessage';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public connectedUser: BehaviorSubject<ConnectedUser | null> =
    new BehaviorSubject<ConnectedUser | null>(null);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
  ) {
    // Init Service
    this._activeFirstAccountFromSessionStorage();
    this._loadActiveAccount();

    this.msalBroadcastService.msalSubject$.subscribe({
      next: (event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS: {
            const payload = event.payload as AuthenticationResult;
            this._onLogin(payload.account);
            break;
          }

          case EventType.LOGOUT_SUCCESS: {
            this._onLogout();
            break;
          }

          case EventType.LOGIN_FAILURE: {
            break;
          }
          case EventType.ACQUIRE_TOKEN_FAILURE: {
            this._onLoginFailure(event.error);
            break;
          }

          default: {
            break;
          }
        }
      },
    });
  }

  public logout(logoutRequest?: EndSessionRequest) {
    this.msalService.logout(logoutRequest);
  }

  public loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  public getcurrentUserRoles(): Array<string> | undefined {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      return activeAccount.idTokenClaims?.roles;
    }
    return [];
  }

  private _onLogin(account: AccountInfo | null) {
    if (!account) {
      return;
    }

    this.msalService.instance.setActiveAccount(account);
    this._loadActiveAccount();
  }

  private _onLogout() {
    this.connectedUser.next(null);
  }

  private _onLoginFailure(error: EventError) {
    this.connectedUser.next(null);
    console.error(error);
    this.router.navigate(['/unauthorized']); // TODO
  }

  private _accountInfoToConnectedUser(
    accountInfo: AccountInfo | null,
  ): null | ConnectedUser {
    if (!accountInfo) return null;

    return {
      username: accountInfo.name,
      roles: [], // TODO
    } as ConnectedUser;
  }

  private _loadActiveAccount() {
    if (!this.msalService.instance.getActiveAccount()) {
      return;
    }
    const activeAccount = this._accountInfoToConnectedUser(
      this.msalService.instance.getActiveAccount(),
    );
    this.connectedUser.next(activeAccount);
  }

  private _activeFirstAccountFromSessionStorage() {
    const account = this.msalService.instance.getAllAccounts();
    if (account && account.length > 0) {
      this.msalService.instance.setActiveAccount(account[0]);
    }
  }
}
