<div
  class="d-flex align-items-center justify-content-between"
  [ngClass]="customCssClass"
  [attr.data-bs-toggle]="tooltip ? 'tooltip' : ''"
  [attr.data-bs-placement]="tooltip ? tooltipPosition : ''"
  [title]="tooltip ? tooltipTitle : ''"
>
  <text-typography [ngClass]="customTextClass">{{ title }}</text-typography>
  <span [ngClass]="color" [class]="!customIconClass ? applyStyle(style) : customIconClass">{{ type }}</span>
  <span [ngClass]="color + ' ' + customDescriptionClass" class="text-body-m">{{ description }}</span>
  <legend-typography>{{ legend }}</legend-typography>
</div>
