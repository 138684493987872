/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button-element',
  templateUrl: './button-element.component.html',
})
export class ButtonElementComponent {
  @Input() type!: string;
  @Input() isDisplayed: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() customCssClass: string | undefined;
}
