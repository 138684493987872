import { Component, ContentChild, Input } from '@angular/core';
import { CardHeaderComponent } from '../../molecules/card/header/card-header.component';
import { CardBodyComponent } from '../../molecules/card/body/card-body.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent {
  @Input() customCssClass: string = '';
  @ContentChild(CardHeaderComponent) cardHeaderComponent!: CardHeaderComponent;
  @ContentChild(CardBodyComponent) cardBodyComponent!: CardBodyComponent;
}
