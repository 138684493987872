import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TabComponent } from '../../molecules/tab/tab.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent> | undefined;
  @Input() customCssClass: string = '';
}
