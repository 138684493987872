import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'link-element',
  templateUrl: './link-element.component.html',
  styleUrls: ['./link-element.component.css'],
})
export class LinkElementComponent {
  @Input() route: string = '';
  @Input() customCssClass: string = '';
}
