import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'legend-typography',
  templateUrl: './legend-typography.component.html',
})
export class LegendTypographyComponent {
  @Input() customCssClass: string | undefined;
}
