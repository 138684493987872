import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'image-element',
  templateUrl: './image-element.component.html',
})
export class ImageElementComponent {
  @Input() customCssClass: string | undefined;
}
