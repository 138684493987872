import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'description-typo',
  templateUrl: './description.component.html',
})
export class DescriptionComponent {
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() isBoldLabel: boolean = false;
}
