import { Pipe, PipeTransform } from '@angular/core';
import { compareDates } from '../utils/date.utils';

@Pipe({
  name: 'dateComparator',
})
export class DateComparatorPipe implements PipeTransform {
  transform(date: Date | null): number | null {
    if (!date) return null;

    return compareDates(new Date(), new Date(date));
  }
}
