import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
})
export class TabComponent {
  @Input() route: string = '';
  @Input() customCssClass: string = '';

  setActiveRouteStyle(): string {
    return window.location.pathname.startsWith(this.route)
      ? 'nav-link active'
      : 'nav-link';
  }
}
