import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Injectable()
export class RefineryCodeInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      const urlWithRefinery = request.url.replace(
        '/api/',
        `/api/${'DGS'}/`
      );
      const modifiedRequest = request.clone({ url: urlWithRefinery });

      return next.handle(modifiedRequest);
    }
}
